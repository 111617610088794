export const media = {
  phoneM: '@media (min-width: 375px)',
  tablet: '@media (min-width: 768px)',
  tabletL: '@media (min-width: 1024px) and (min-height: 1280)',
  desktop: '@media (min-width: 1025px)',
  desktopS: '@media (min-width: 1240px)',
  desktopM: '@media (min-width: 1440px)',
  desktopL: '@media (min-width: 1600px)',
  desktopXL: '@media (min-width: 1920px)',
};
export const mediaImages = {
  tablet: '(min-width: 768px)',
  tabletL: '(min-width: 1024px) and (min-height: 1280)',
  desktop: '(min-width: 1025px)',
  desktopL: '(min-width: 1600px)',
  desktopXL: '(min-width: 1920px)',
};
