import styled from 'styled-components';
import {media} from '@brightlive/shared/styles/breakpoints';

const Ag: Record<string, React.ElementType> = {};

Ag.H1 = styled.h1`
  font-family: ${props => props.theme.fonts.primary};
  color: ${props => props.theme.textColor.textDefault};
  font-size: 32px;
  font-weight: 700;
  line-height: 36px;
  font-display: swap;

  ${media.desktop} {
    font-size: 42px;
    line-height: 48px;
    font-weight: 500;
  } ;
`;

Ag.H2 = styled.h2`
  font-family: ${props => props.theme.fonts.primary};
  color: ${props => props.theme.textColor.textDefault};
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  font-display: swap;

  ${media.desktop} {
    font-size: 32px;
    line-height: 36px;
    font-weight: 600;
  } ;
`;

Ag.H3 = styled.h3`
  font-family: ${props => props.theme.fonts.primary};
  color: ${props => props.theme.textColor.textDefault};
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  font-display: swap;

  ${media.desktop} {
    font-size: 24px;
    line-height: 30px;
  } ;
`;

Ag.H4 = styled.h4`
  font-family: ${props => props.theme.fonts.primary};
  color: ${props => props.theme.textColor.textDefault};
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  font-style: italic;
  font-display: swap;

  ${media.desktop} {
    font-size: 24px;
    line-height: 30px;
  } ;
`;

Ag.H5 = styled.h5`
  font-family: ${props => props.theme.fonts.primary};
  color: ${props => props.theme.textColor.textDefault};
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  font-display: swap;

  ${media.desktop} {
    font-size: 18px;
    line-height: 24px;
  } ;
`;

Ag.H6 = styled.h6`
  font-family: ${props => props.theme.fonts.primary};
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.2em;
  color: ${props => props.theme.color.grey};
  font-display: swap;

  ${media.desktop} {
    font-size: 16px;
    letter-spacing: 24px;
  } ;
`;

Ag.Link = styled.span`
  font-size: 14px;
  font-weight: 700;
  font-family: ${props => props.theme.fonts.primary};
  color: ${props => props.theme.color.blue};
  text-decoration: underline;
  cursor: pointer;
  font-display: swap;
`;

Ag.Caption = styled.p`
  font-family: ${props => props.theme.fonts.primary};
  color: ${props => props.theme.textColor.textDefault};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-style: italic;
  font-display: swap;
`;

Ag.Body = styled.p`
  font-family: ${props => props.theme.fonts.primary};
  color: ${props => props.theme.textColor.textDefault};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-display: swap;
`;

Ag.Bold = styled.span`
  font-weight: 700;
`;

Ag.ModalTitle = styled.h2`
  font-family: ${props => props.theme.fonts.primary};
  color: ${props => props.theme.textColor.textDefault};
  font-weight: 500;
  font-display: swap;
  font-size: 24px;
  line-height: 30px;
`;

Ag.ModalSubtitle = styled.p`
  font-family: ${props => props.theme.fonts.primary};
  color: ${props => props.theme.textColor.textDefault};
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  text-align: center;
  font-display: swap;
`;

Ag.SectionTitle = styled.p`
  font-family: ${props => props.theme.fonts.primary};
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 1px;
  color: ${props => props.theme.color.white};
  font-display: swap;
`;

// V3 TYPOGRAPHY
Ag.DisplayLarge = styled.h1`
  font-family: ${props => props.theme.fonts.secondary};
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 44px;
  color: ${props => props.theme.textColor.textDefault};

  ${media.desktop} {
    font-size: 64px;
    line-height: 72px;
  }
`;

Ag.DisplaySmall = styled.h2`
  font-family: ${props => props.theme.fonts.secondary};
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: ${props => props.theme.textColor.textDefault};
`;

Ag.HeadingXSmall = styled.h6`
  font-family: ${props => props.theme.fonts.primary};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.textColor.textDefault};
`;

export const headingSmallMobile = `
  font-size: 18px;
  line-height: 24px;
`;

export const headingSmallDesktop = `
  font-size: 20px;
  line-height: 28px;
`;

Ag.HeadingSmall = styled.h5`
  font-family: ${props => props.theme.fonts.primary};
  font-style: normal;
  font-weight: 500;
  ${headingSmallMobile}
  color: ${props => props.theme.textColor.textDefault};

  ${media.desktop} {
    font-weight: 500;
    ${headingSmallDesktop}
  }
`;

export const headingMedium = `
  font-size: 24px;
  line-height: 32px;
`;

Ag.HeadingMedium = styled.h4`
  font-family: ${props => props.theme.fonts.primary};
  font-style: normal;
  font-weight: 500;
  ${headingMedium}
  color: ${props => props.theme.textColor.textDefault};
`;

Ag.HeadingLarge = styled.h1`
  font-family: ${props => props.theme.fonts.primary};
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.01em;
  color: ${props => props.theme.textColor.textDefault};
`;

Ag.ParagraphSmall = styled.p`
  font-family: ${props => props.theme.fonts.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.textColor.textDefault};
`;

export const paragraphMediumMobile = `
  font-size: 15px;
  line-height: 20px;
`;

Ag.ParagraphMedium = styled.p`
  font-family: ${props => props.theme.fonts.primary};
  font-style: normal;
  font-weight: 400;
  ${paragraphMediumMobile}
  color: ${props => props.theme.textColor.textDefault};

  ${media.desktop} {
    font-size: 16px;
    line-height: 24px;
  }
`;

Ag.ParagraphLarge = styled.p`
  font-family: ${props => props.theme.fonts.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: ${props => props.theme.textColor.textDefault};

  ${media.desktop} {
    font-size: 20px;
    line-height: 28px;
  }
`;

Ag.Overline = styled.p`
  font-family: ${props => props.theme.fonts.primary};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: ${props => props.theme.textColor.textDefault};
`;

Ag.Meta = styled.p`
  font-family: ${props => props.theme.fonts.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.textColor.textDefault};
`;

Ag.Anchor = styled.a`
  text-decoration: underline;
  font-weight: 700;
  color: inherit;
  cursor: pointer;
`;

Ag.FormInput = styled.p`
  font-family: ${props => props.theme.fonts.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.textColor.textDefault};
`;

Ag.UnderlineHeading = styled.h2`
  position: relative;
  font-family: ${props => props.theme.fonts.primary};
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  color: ${props => props.theme.textColor.textDefault};

  span {
    position: absolute;
    top: 0;
    left: 0;
  }

  &:before {
    position: relative;
    display: inline;
    content: attr(data-text);
    color: transparent;
    top: -2px;
    background-image: linear-gradient(
      180deg,
      transparent 60%,
      ${props => props.theme.color.indigo300} 0,
      ${props => props.theme.color.indigo300} 100%,
      transparent 0
    );
  }

  ${media.desktop} {
    font-size: 32px;
    line-height: 40px;
  }
`;

export default Ag;
